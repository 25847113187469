<mat-form-field>
  <mat-label>Search for text</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input />
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <!-- ID Column -->

    <!-- type Column -->
    <ng-container matColumnDef="type">
      <th style="width: 25px" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="type-col">
        <mat-icon *ngIf="row.type === 'audio'">mic</mat-icon>
        <mat-icon *ngIf="row.type === 'video'">videocam</mat-icon>
      </td>
    </ng-container>

    <!-- text Column -->
    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef style="width: 100%">Recording</th>
      <td mat-cell *matCellDef="let row" class="text-col">
        <div *ngIf="row.title">{{ row.title }}</div>
        <span *ngIf="!row.title">{{ row.text }}</span>
      </td>
    </ng-container>

    <!-- media Column -->
    <ng-container matColumnDef="media">
      <th style="width: 60px" mat-header-cell *matHeaderCellDef>Media</th>
      <td mat-cell *matCellDef="let row" class="media-col">
        <span *ngIf="row.media">Recorded {{ row.media.updated | date }}</span>
        <mat-icon *ngIf="locked && locked[row.docId]">lock</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="goToRecording(row)"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" *ngIf="input.value !== ''">
        No data matching the filter "{{ input.value }}"
      </td>
      <td class="mat-cell" colspan="4" *ngIf="input.value === ''">
        This project has no translations listed
      </td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[100, 50, 25]"></mat-paginator>
</div>
