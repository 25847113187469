export default [
  {
    text:
      'A Short Pause\n•\tSit down and close your eyes\n•\tListen to your breath as it goes in and out. \n•\tNotice how you feel. \n•\tTake a few more deep breaths.\n•\tWhen you are ready open your eyes again. Do you feel any different? \n•\tRemind yourself that “Everything is ok”',
    type: 'audio',
    title: 'A Short Pause',
  },
  {
    text:
      '2 Truths, 1 Lie\n•\tThink of three statements about yourself. Two must be true and one must be a lie.\n•\tIt might help to think of wishes, dreams, likes or dislikes. For example, I hate bananas, I love ice cream and I really wish I could sing. \n•\tSay them to your partner or friends. Ask them to guess which one is a lie. \n•\tDid they guess correctly? Was it a surprise?\n•\tNow ask them to do the same. Did you learn something new about each other? ',
    type: 'video',
    title: '2 Truths, 1 Lie',
  },
  {
    text:
      'Family Work Out\n•\tChoose a sport or exercise to do together\n•\tDecide on a schedule. Are you going to do it every day? For how long? \n•\tTry it out for a week. \n•\tAt the end of the week, ask yourself and your family these questions. How´s it going? How did you feel at the start of the week? How do you feel at the end of the week? What can we do differently next week?',
    type: 'audio',
    title: 'Family Work Out',
  },
  {
    text:
      'Crazy Chicken\n•\tShake your hand in the air 8 times while counting\n•\tDo it as fast and loud as you can\n•\tRepeat with your other hand and both feet\n•\tDo it 7 times, then 6 times until you get to 1.\n•\tCall out “Crazy chicken” as loud as you can!\n•\tHave you released all your stress? If not, do it again even faster and louder! ',
    type: 'video',
    title: 'Crazy Chicken',
  },
  {
    text:
      'Taking a Pause is a simple relaxation activity that you can do whenever you are feeling stressed or worried. It can also be helpful when you find your child is irritating you or has done something wrong.',
    type: 'audio',
    title: 'Taking a Pause',
  },
];
