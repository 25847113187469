<div class="page">
  <div style="display: flex; align-items: center; margin-bottom: 1em">
    <app-back-button></app-back-button>
    <h2 style="margin: 0; flex: 1" [title]="dataService.activeProject.deployment">
      {{dataService.activeProject.label}}
    </h2>
    <button
      mat-button
      routerLink="/admin"
      [disabled]="!dataService.userIsProjectAdmin"
      [title]="dataService.userIsProjectAdmin ? 'Import Data' : 'Only project admins can manage project'"
    >
      <mat-icon>settings</mat-icon>Manage Project
    </button>
  </div>
  <app-project-progress [project]="dataService.activeProject$ | async"></app-project-progress>
  <app-recording-list
    [mediaSummary]="(dataService.activeProject$ | async).mediaSummary"
    [lockedDocs]="dataService.lockedDocs$ | async"
  ></app-recording-list>
</div>

<!-- <button
      mat-button
      routerLink="/import"
      [disabled]="!dataService.userIsProjectAdmin"
      [title]="dataService.userIsProjectAdmin ? 'Import Data' : 'Only project admins can import data'"
    >
      <mat-icon>input</mat-icon>Import Data
    </button> -->
