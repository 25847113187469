import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecordPage } from './pages/record/record.page';
import { RecordEditorPage } from './pages/record/editor/editor.page';
import { UserLoggedInGuard, ActiveProjectGuard, ProjectAdminGuard } from './pages/route-guards';
import { ImportPage } from './pages/import/import.page';
import { ProjectsPage } from './pages/projects/projects.page';
import { SummaryPage } from './pages/summary/summary.page';
import { AdminPage } from './pages/admin/admin.page';

const routes: Routes = [
  {
    path: '',
    component: ProjectsPage,
    data: { animation: 'ProjectsPage' },
  },
  {
    path: 'record',
    component: RecordPage,
    canActivate: [ActiveProjectGuard],
    data: { animation: 'RecordPage' },
  },
  {
    path: 'record/:docId',
    component: RecordEditorPage,
    canActivate: [ActiveProjectGuard, UserLoggedInGuard],
    data: { animation: 'RecordEditorPage' },
  },
  {
    path: 'import',
    component: ImportPage,
    canActivate: [ActiveProjectGuard, ProjectAdminGuard],
    data: { animation: 'ImportPage' },
  },
  {
    path: 'summary',
    component: SummaryPage,
    canActivate: [ActiveProjectGuard],
    data: { animation: 'SummaryPage' },
  },
  {
    path: 'admin',
    component: AdminPage,
    canActivate: [ActiveProjectGuard, ProjectAdminGuard],
    data: { animation: 'AdminPage' },
  },
  { redirectTo: '', path: '**' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
