import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { countries } from 'countries-list';
import Langs from 'langs';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/services/data.service';
import { AuthService } from 'src/services/auth.service';
import { IProject } from 'src/types/types';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-editor',
  templateUrl: './project-editor.html',
  styleUrls: ['./project-editor.scss'],
})
export class ProjectEditorComponent implements OnInit {
  isSaving = false;
  projectForm: FormGroup;
  countryList: { label: string; value: string }[];
  languagesList: ILanguage[];
  mode: 'create' | 'edit' = 'create';
  /** Editable project passed directly to project  */
  @Input() set project(project: IProject) {
    if (project) {
      this.mode = 'edit';
      this.projectForm.patchValue(project);
    }
  }
  @ViewChild('confirmDelete') confirmDeleteDialog: TemplateRef<any>;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private dataService: DataService,
    private authService: AuthService,
    private router: Router
  ) {
    this.countryList = this.generateCountryList();
    this.initialiseProjectForm();
  }

  initialiseProjectForm() {
    // Custom template for hackathon forms
    const form: { [key in keyof IProject]: FormControl } = {
      // Hackathon only - these fields are pre-populated
      // TODO - populate from env file instead
      project: new FormControl({ value: environment.DEFAULT_PROJECT, disabled: true }, [
        Validators.required,
      ]),
      subproject: new FormControl({ value: 'Hackathon', disabled: true }, [Validators.required]),
      // Fields populated from form
      label: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      language: new FormControl('', [Validators.required]),
      public: new FormControl(true),
      variant: new FormControl(''),
      // fields populated automatically on upload
      admins: new FormControl([]),
      deployment: new FormControl(null),
      description: new FormControl(''),
      users: new FormControl([]),
      ownerEmail: new FormControl(''),
      // fields populated by backend
      dbPath: new FormControl(''),
      mediaSummary: new FormControl({}),
    };
    this.projectForm = this.fb.group(form);
  }

  ngOnInit() {}
  async save() {
    this.isSaving = true;
    // use getRawValue instead of value to include value of disabled fields
    const v = this.projectForm.getRawValue() as IProject;
    // create a unique deployment id (some country-language combinations may still have multiple variants)
    // TODO - in future this unlikely to be required, deployment ids can just all be random
    const randomSuffix = Math.random().toString(36).substr(2, 10);
    const project: IProject = {
      ...v,
      // default ID, will be replaced if previous ID already exists
      deployment: v.deployment || `${v.country}-${v.language}-${randomSuffix}`,
      // by default populate current user as admin and user
      admins: [this.authService.user.email.toLowerCase()],
      users: [this.authService.user.email.toLowerCase()],
      ownerEmail: v.ownerEmail || this.authService.user.email,
    };
    const allowOverride = this.mode === 'edit';
    await this.dataService.saveProject(project, allowOverride);
    this.isSaving = false;
    this.dialog.closeAll();
  }
  delete() {
    const confirmation = this.dialog.open(this.confirmDeleteDialog);
    confirmation.afterClosed().subscribe(async (shouldDelete) => {
      if (shouldDelete) {
        await this.dataService.deleteActiveProject();
        this.router.navigate(['/']);
      }
    });
  }
  loadCountryLanguages(alpha2Code: string) {
    const nativeLanguages = countries[alpha2Code].languages;

    this.languagesList = [...MISSING_LANGUAGES, ...Langs.all()]
      .map((l) => ({ local: l.local, name: l.name, iso1Code: l['1'], iso3Code: l['3'] }))
      .sort((a: ILanguage, b: ILanguage) => {
        if (nativeLanguages.includes(b.iso1Code)) {
          return 1;
        }
        if (nativeLanguages.includes(a.iso1Code)) {
          return -1;
        }

        return a.name > b.name ? 1 : -1;
      });
    this.projectForm.patchValue({ language: null });
  }

  private generateCountryList() {
    return Object.entries(countries)
      .map(([alpha2Code, data]) => ({ value: alpha2Code, label: data.name }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  /**
   *
   * https://www.npmjs.com/package/countries-list
   * https://www.npmjs.com/package/langs
   * https://en.wikipedia.org/wiki/ISO_639_macrolanguage
   * https://en.wikipedia.org/wiki/ISO_3166-1
   */
}

interface ILanguage {
  local: string;
  name: string;
  iso1Code: string;
  iso3Code: string;
}

// https://github.com/adlawson/nodejs-langs/issues/13
// https://www.npmjs.com/package/iso-639-3
// https://en.wikipedia.org/wiki/ISO_639-3
// https://en.wikipedia.org/wiki/ISO_639:c
const MISSING_LANGUAGES = [
  { local: '官話; 北方話', name: 'Mandarin Chinese', 1: 'zh', 3: 'cmn' },
  { local: '廣東話', name: 'Yue Chinese (Cantonese)', 1: 'zh', 3: 'yue' },
];
