import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FilesizePipe } from '../pipes/filesize.pipe';
import { AuthCheckComponent } from './auth-check/auth-check.component';
import { BackButtonComponent } from './back-button/back-button';

import { EditorComponent } from './editor/editor.component';
import { AppLoginComponent } from './login/login';
import { MainHeaderComponent } from './main-header/main-header';
import { MaterialComponentsModule } from './material.components';
import { ProjectCardComponent } from './project-card/project-card';
import { ProjectEditorComponent } from './project-editor/project-editor';
import { ProjectEntryEditorComponent } from './project-entry-editor/project-entry-editor';
import { ProjectFilterComponent } from './project-filter/project-filter';
import { ProjectProgressComponent } from './project-progress/project-progress.component';
import { ProjectSummaryComponent } from './project-summary/project-summary';
import { RecorderComponent } from './recorder/recorder.component';
import { RecordingGuidelinesComponent } from './recording-guidelines/recording-guidelines.component';
import { RecordingListComponent } from './recording-list/recording-list.component';

const AppComponents = [
  AppLoginComponent,
  AuthCheckComponent,
  BackButtonComponent,
  EditorComponent,
  FilesizePipe,
  MainHeaderComponent,
  ProjectCardComponent,
  ProjectEditorComponent,
  ProjectEntryEditorComponent,
  ProjectFilterComponent,
  ProjectProgressComponent,
  ProjectSummaryComponent,
  RecorderComponent,
  RecordingGuidelinesComponent,
  RecordingListComponent,
];

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CommonModule, MaterialComponentsModule, RouterModule],
  exports: [...AppComponents],
  declarations: [...AppComponents],
  providers: [],
})
export class AppComponentsModule {}
