import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/services/data.service';
import { DATA_TEMPLATES } from 'src/data/templates';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { IProject } from 'src/types/types';

@Component({
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage implements OnInit {
  isLoading = false;
  constructor(public dataService: DataService) {}

  ngOnInit(): void {}

  async loadStringFromTemplate(template_id: string, wipeProjectData = false) {
    // as it might take a time for all the background functions to process just loading state
    // to prevent duplicate submission
    this.isLoading = true;
    console.log('load string from templates', template_id, DATA_TEMPLATES);
    const entries = DATA_TEMPLATES[template_id];
    if (entries) {
      if (wipeProjectData) {
        await this.dataService.wipeActiveProject();
        // wait for backend triggers to also complete that delete mediaSummary entries on doc delete
        await this.dataService.activeProject$
          .pipe(takeWhile<IProject>((p) => Object.keys(p.mediaSummary).length > 0))
          .toPromise();
      }
      // TODO - better data prep methods available in import page. Should merge and refactor
      await this.dataService.uploadDBDocuments(entries);
    } else {
      console.error('template not found', template_id, 'available:', Object.keys(DATA_TEMPLATES));
      throw new Error('no template exists for ' + template_id);
    }
  }
}
