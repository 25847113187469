import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-back-button',
  template: `<button mat-icon-button (click)="back()"><mat-icon>arrow_back</mat-icon></button>`,
  styles: [
    `
      :host {
        float: left;
      }
    `,
  ],
})
export class BackButtonComponent {
  constructor(private location: Location) {}

  back() {
    this.location.back();
  }
}
