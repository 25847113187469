import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user$ = new BehaviorSubject<firebase.User>(null);
  constructor(private afAuth: AngularFireAuth) {
    this._subscribeToAuthChanges();
  }
  get user() {
    return this.user$.value;
  }

  signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    return this.afAuth.signInWithPopup(provider);
  }
  signInWithEmailPassword(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }
  signUpWithEmailPassword(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  signInAnonymously() {
    return this.afAuth.signInAnonymously();
  }
  signOut() {
    return this.afAuth.signOut();
  }

  _subscribeToAuthChanges() {
    this.afAuth.onAuthStateChanged(this.user$);
  }
}
