<div class="page">
  <img src="assets/logos/banner.png" style="width: 100%; margin-bottom: 2em" />
  <div *ngIf="dataService.user && dataService.user.email">
    <div style="display: flex; justify-content: space-between">
      <h2>My Projects</h2>
      <app-auth-check allow="super-admin">
        <button mat-button (click)="addProject()"><mat-icon>add</mat-icon>New Project</button>
      </app-auth-check>
    </div>
    <div *ngIf="(dataService.userProjects$ | async).length===0">
      <p *ngIf="dataService.userProjectsLoading">Loading User Projects...</p>
      <p *ngIf="!dataService.userProjectsLoading">You are not a member of any projects</p>
    </div>
    <div class="project-card-container">
      <app-project-card
        *ngFor="let project of (dataService.userProjects$ | async)"
        [project]="project"
        (click)="goToProject(project)"
      ></app-project-card>
    </div>
  </div>
  <div>
    <!-- <button mat-button><mat-icon>add</mat-icon>Create New Project</button> -->
    <div *ngIf="(dataService.publicProjects$ | async) as publicProjects">
      <div *ngIf="publicProjects.length>0">
        <h2 style="margin-top: 2em">Public Projects</h2>
        <app-project-filter
          [projects]="publicProjects"
          (projectsFiltered)="filteredPublicProjects=$event"
        ></app-project-filter>

        <div class="project-card-container">
          <app-project-card
            *ngFor="let project of filteredPublicProjects"
            [project]="project"
            (click)="goToProject(project)"
          ></app-project-card>
        </div>
      </div>
    </div>
  </div>
</div>
