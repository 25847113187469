import { NgModule } from '@angular/core';

// Pages
import { RecordPage } from 'src/app/pages/record/record.page';
import { RecordEditorPage } from 'src/app/pages/record/editor/editor.page';
import { ImportPage } from 'src/app/pages/import/import.page';

import { ProjectsPage } from 'src/app/pages/projects/projects.page';
import { SummaryPage } from 'src/app/pages/summary/summary.page';
import { AdminPage } from './admin/admin.page';
import { MaterialComponentsModule } from '../components/material.components';
import { AppComponentsModule } from '../components/app-components.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RouterModule } from '@angular/router';

const PAGES = [RecordPage, ProjectsPage, RecordEditorPage, ImportPage, SummaryPage, AdminPage];

@NgModule({
  imports: [
    MaterialComponentsModule,
    AppComponentsModule,
    FormsModule,
    CommonModule,
    NgxFileDropModule,
    RouterModule,
  ],
  exports: [...PAGES],
  declarations: [...PAGES],
  providers: [],
})
export class AppPagesModule {}
