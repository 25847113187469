import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectEditorComponent } from 'src/app/components/project-editor/project-editor';
import { DataService } from 'src/services/data.service';
import { IProject } from 'src/types/types';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects.page.html',
  styleUrls: ['./projects.page.scss'],
})
export class ProjectsPage implements OnInit {
  constructor(public dataService: DataService, private dialog: MatDialog, private router: Router) {}
  publicProjects: IProject[];
  filteredPublicProjects: IProject[] = [];

  ngOnInit() {
    // refresh user projects to see updated summaries
    this.dataService.reloadUserProjects();
  }

  addProject() {
    const dialogRef = this.dialog.open(ProjectEditorComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
  goToProject(project: IProject) {
    console.log('loading project', project);
    this.dataService.setActiveProject(project);
    this.router.navigate(['/record']);
  }
}
