import firebaseConfig from './firebaseConfig.json';
import packageJSON from '../../package.json';
import strings from './strings.json';

if (!firebaseConfig) {
  throw new Error('Firebase config required in environments/firebase.config.json');
}

export default {
  firebase: firebaseConfig,
  version: packageJSON.version,
  strings,
};
