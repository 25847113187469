import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from 'src/services/data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMediaSummary, IProject, IMediaSummaryEntry, IMediaDocument } from 'src/types/types';
import { MatDialog } from '@angular/material/dialog';
import { ProjectEntryEditorComponent } from '../project-entry-editor/project-entry-editor';

@Component({
  selector: 'app-project-summary',
  templateUrl: './project-summary.html',
  styleUrls: ['./project-summary.scss'],
})
export class ProjectSummaryComponent implements OnInit, OnDestroy {
  /** Use the same component to show viewable summary and editable admin features */
  @Input() mode: 'view' | 'admin' = 'view';

  displayedColumns = ['text', 'downloadUrl'];
  destroyed$ = new Subject();
  datasource: IDatasourceEl[] = [];

  constructor(public dataService: DataService, private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.mode === 'admin') {
      this.displayedColumns = ['id', 'title', 'text', 'editButton'];
    }
    this.dataService.activeProject$.pipe(takeUntil(this.destroyed$)).subscribe((project) => {
      console.log('active project summary', project);
      this.datasource = Object.entries(project.mediaSummary)
        .map<IDatasourceEl>(([key, value]) => {
          // add id to media summary entry from key and determine if user can delete
          (value as IDatasourceEl)._canDeleteMedia = this._canUserDeleteMedia(value.media);
          return { ...value, id: key };
        })
        .filter((v) => {
          // in admin mode show everything
          if (this.mode === 'admin') {
            return true;
          } else {
            // otherwise only show entries with recorded media
            return v.media ? true : false;
          }
        })
        .sort((a, b) => (a.id > b.id ? 1 : -1));
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }
  editEntry(entry: IMediaDocument) {
    this.dialog.open(ProjectEntryEditorComponent, { data: { entry }, width: '400px' });
  }

  /**
   * Only allow admins and media owners to delete
   */
  private _canUserDeleteMedia(media: IMediaSummaryEntry['media']) {
    if (!media) {
      return false;
    }
    if (this.dataService.userIsProjectAdmin) {
      return true;
    }
    return media.uploadedByEmail === this.dataService.user.email;
  }

  async deleteRecording(entry: IMediaSummary['entries'][0]) {
    const { dbPath } = this.dataService.activeProject$.value;
    const index = this.datasource.indexOf(entry);
    this.datasource[index]._deleting = true;
    const doc = await this.dataService.getDBDocument(`${dbPath}/${entry.id}`);
    if (doc.exists) {
      const { name } = doc.data().media;
      this.dataService.deleteStorageFile(name);
    }
  }
}

// The datasource is the same as project media summary entries, with additional metadata field
type IDatasourceEl = IProject['mediaSummary'][0] & {
  _deleting?: boolean;
  _canDeleteMedia?: boolean;
  id: string;
};
