<h2 *ngIf="mode === 'create'">Create A Project</h2>
<h3 *ngIf="mode === 'edit'" style="margin-top: 1em">Edit Project</h3>
<form [formGroup]="projectForm">
  <div>
    <mat-form-field appearance="outline" style="width: calc(50% - 5px); margin-right: 5px">
      <mat-label>Project</mat-label>
      <input matInput formControlName="project" />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: calc(50% - 5px); margin-left: 5px">
      <mat-label>Subproject</mat-label>
      <input matInput formControlName="subproject" />
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 1em">
    <mat-label>Label*</mat-label>
    <input matInput formControlName="label" />
    <mat-hint align="end">This will be shown on the main page</mat-hint>
  </mat-form-field>
  <!-- </mat-form-field> -->
  <!-- <mat-form-field appearance="outline" style="width:100%; margin-bottom:2em">
        <mat-label>Project Template</mat-label>
        <mat-select>
          <mat-option value=""></mat-option>
          <mat-option value="Hackathon">Hackathon</mat-option>
        </mat-select>
        <input matInput formControlName="name" />
        <mat-hint align="end">Optionally load data from a specific template</mat-hint>
      </mat-form-field> -->
  <!-- Only allow country/language setting on create as it currently affects the deployment id -->
  <div *ngIf="mode === 'create'">
    <mat-form-field appearance="outline" style="width: calc(50% - 5px); margin-right: 5px">
      <mat-label>Country*</mat-label>
      <mat-select formControlName="country" (selectionChange)="loadCountryLanguages($event.value)">
        <mat-option *ngFor="let country of countryList" [value]="country.value"
          >{{ country.label }}</mat-option
        >
      </mat-select>
      <!-- <mat-hint align="end">The country specified for translators</mat-hint> -->
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: calc(50% - 5px); margin-left: 5px">
      <mat-label>Language*</mat-label>
      <mat-select formControlName="language">
        <mat-option *ngFor="let language of languagesList" [value]="language.iso3Code"
          >{{ language.name }} ({{ language.local }})</mat-option
        >
      </mat-select>
      <!-- <mat-hint align="end">The target translation language</mat-hint> -->
    </mat-form-field>
    <!-- <mat-form-field appearance="outline" style="width:calc(33% - 5px);">
          <mat-label>Variant</mat-label>
          <input matInput formControlName="variant" />
          <mat-hint align="end">Optional</mat-hint>
        </mat-form-field> -->
  </div>
  <mat-checkbox style="margin-bottom: 2em" formControlName="public" color="primary"
    >Public</mat-checkbox
  >
  <!-- <mat-error *ngIf="projectForm.invalid">Min size: 10px</mat-error> -->
</form>

<div mat-dialog-actions align="end" *ngIf="mode === 'create'">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="save()" [disabled]="projectForm.invalid || isSaving">
    Create Project
  </button>
</div>
<div *ngIf="mode === 'edit'" style="display: flex; justify-content: space-between; margin-top: 2em">
  <button mat-button color="warn" (click)="delete()">Delete Project</button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="projectForm.invalid || isSaving"
  >
    Save
  </button>
</div>
<ng-template #confirmDelete>
  <h2 matDialogTitle>Delete Project</h2>
  <mat-dialog-content>
    <p>Are you sure you want to delete this project?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Cancel</button>
    <button mat-button [matDialogClose]="true" color="primary">Delete Project</button>
  </mat-dialog-actions>
</ng-template>
