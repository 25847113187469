import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { countries } from 'countries-list';
import { DataService } from 'src/services/data.service';
import { IProject } from 'src/types/types';

@Component({
  selector: 'app-project-filter',
  template: `<mat-form-field appearance="outline">
    <mat-label>Filter by Country</mat-label>
    <mat-select (selectionChange)="filterProjectsByCountry($event.value)">
      <mat-option [value]="null">All</mat-option>
      <mat-option *ngFor="let country of filterCountries" [value]="country.value">
        {{ country.label }}
      </mat-option>
    </mat-select>
  </mat-form-field> `,
})
export class ProjectFilterComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  filterCountries: { label: string; value: string }[] = [];
  _projects: IProject[];
  @Input() set projects(projects: IProject[]) {
    this._projects = projects;
    this.generateProjectCountryFilterList(projects);
  }
  @Output() projectsFiltered = new EventEmitter<IProject[]>();

  constructor(public dataService: DataService) {}

  ngOnInit() {
    this.dataService.publicProjects$.pipe(takeUntil(this.destroyed$)).subscribe((projects) => {
      this.generateProjectCountryFilterList(projects);
      this.filterProjectsByCountry(null);
    });
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
  }
  filterProjectsByCountry(country: string) {
    const filtered = country ? this._projects.filter((p) => p.country === country) : this._projects;
    this.projectsFiltered.next(filtered);
  }

  generateProjectCountryFilterList(projects: IProject[]) {
    this.filterCountries = [...new Set(projects.map((p) => p.country))]
      .map((code) => ({
        value: code,
        label: countries[code].name,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }
}
