import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { DataService } from 'src/services/data.service';
import { IDBDocument, IMediaDocument } from 'src/types/types';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import',
  templateUrl: './import.page.html',
  styleUrls: ['./import.page.scss'],
})
export class ImportPage implements OnInit {
  fileEntry: FileSystemFileEntry;
  summary: { import: any[]; ignore: any[] };
  errorMsg: string;
  isImporting = false;
  constructor(
    public dataService: DataService,
    private zone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {}

  fileDropped(files: NgxFileDropEntry[]) {
    this.errorMsg = undefined;
    if (files[0]) {
      const entry = files[0].fileEntry as FileSystemFileEntry;
      if (entry.isFile) {
        this.fileEntry = entry;
        const reader = new FileReader();
        entry.file((file) => {
          reader.readAsText(file);
          reader.onload = (e) => {
            // run in zone as callback will not automatically trigger change detction
            this.zone.run(() => {
              try {
                const jsonData = JSON.parse(e.target.result as string);
                this.processEntries(jsonData);
              } catch (error) {
                this.errorMsg = error.message;
              }
            });
          };
        });
      }
    }
  }

  cancel() {
    this.summary = undefined;
    this.fileEntry = undefined;
  }
  async import() {
    this.isImporting = true;
    await this.dataService.uploadDBDocuments(this.summary.import);
    this.router.navigate(['/record']);
  }

  processEntries(entries: any[]) {
    if (!Array.isArray(entries)) {
      this.errorMsg = 'JSON does not contain an array of entries';
      return;
    }
    try {
      const summary = { import: [], ignore: [] };
      entries.forEach((e) => {
        if (e.text) {
          const doc: IMediaDocument = {
            text: e.text,
            id: e.id || this._generateIDFromText(e.text),
            type: e.type || 'audio',
            title: e.title || null,
            meta: {},
          };
          // populate any additional fields as metadata
          Object.keys(e).forEach((k) => {
            if (!doc.hasOwnProperty(k)) {
              doc.meta[k] = e[k];
            }
          });
          summary.import.push(doc);
        } else {
          summary.ignore.push(e);
        }
      });
      this.summary = summary;
    } catch (error) {
      this.errorMsg = 'JSON not formatted correctly';
    }
  }

  /**
   * Strip any non alphanumeric characters and replace with underscores.
   * Additionally ensure text does not start with underscore
   */
  private _generateIDFromText(text: string) {
    text = text.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
    // prevent IDs starting with underscore
    if (text.charAt(0) === '_') {
      text = text.substr(1);
      return this._generateIDFromText(text);
    }
    return text;
  }
}
