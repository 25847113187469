import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import * as firebase from 'firebase/app';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import {
  IDBDocument,
  IDBDocumentDL,
  IProject,
  IProjectMeta,
  IStorageUploadMetadata,
} from 'src/types/types';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

// documents retrieved from database have standard metadata alongside whatever other saved data
type IDoc = IDBDocumentDL & { [key: string]: any };
let count = 0;

@Injectable({ providedIn: 'root' })
export class DataService {
  userLockedDocuments$ = new BehaviorSubject<IDoc[]>([]);
  publicProjects$ = new BehaviorSubject<IProject[]>([]);
  userProjects$ = new BehaviorSubject<IProject[]>([]);
  userProjectsLoading = true;
  activeProject$ = new BehaviorSubject<IProject>(null);
  lockedDocs$ = new BehaviorSubject<IDoc[]>(null);
  // TODO - likely rename project to org (as other code referring to projects are actually subprojects)
  projectMeta$ = new BehaviorSubject<IProjectMeta>(null);
  userIsProjectAdmin = false;

  private subscriptions: { [identifier: string]: Subscription } = {};

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private authService: AuthService
  ) {
    // set default demo db when working locally to make life easier
    // if (location.hostname === 'localhost') {
    //   this.setActiveProject(MOCK_PROJECTS[0]);
    // }
    this._getPublicProjects();
    this._getProjectMeta();
    this.authService.user$.subscribe((u) => {
      this._updateUserDocs(u);
      this._checkUserProjectPermissions();
    });
    const STORAGE_TIMEOUT = 10 * 60 * 1000;
    this.storage.storage.setMaxOperationRetryTime(STORAGE_TIMEOUT);
  }

  // getter helpers for commonly retrieved objects
  get activeProject() {
    return this.activeProject$.value;
  }
  get user() {
    return this.authService.user;
  }
  get dbPath() {
    return this.activeProject$.value.dbPath;
  }

  /****************************************************************************
   * Public Methods
   ***************************************************************************/

  async saveProject(p: IProject, allowOverride = false) {
    const { project, subproject, deployment, language } = p;

    const ref = `project/${project}/subproject/${subproject}/deployment/${deployment}/language/${language}`;
    // Create project if doesn't exist
    const { exists } = await this.afs.doc(ref).get().toPromise();
    if (exists && !allowOverride) {
      console.log('project already exists');
      throw new Error('A project with that name already exists');
    } else {
      await this.afs.doc(ref).set(p);
      // TODO - would it be possible to update without fully refreshing data?
      // (less important if subscribing to single summary of project list)
      this.reloadUserProjects();
      this._getPublicProjects();
    }
    // Populate data
  }

  /**
   *  Note - this is actually a subproject and may be renamed in the future
   */
  async setActiveProject(project: IProject) {
    if (this.activeProject$.value?.dbPath !== project.dbPath) {
      this.activeProject$.next(project);
      this._subscribeToActiveProject(project);
      this.lockedDocs$.next(null);
      this._subscribeToLockedDocUpdates();
      this._checkUserProjectPermissions();
      if (this.user) {
        this._updateUserDocs(this.user);
      }
    }
  }

  public async deleteActiveProject() {
    const { project, subproject, deployment, language } = this.activeProject;
    const ref = `project/${project}/subproject/${subproject}/deployment/${deployment}/language/${language}`;
    await this.afs.doc(ref).delete();
    this.setActiveProject(null);
    this.reloadUserProjects();
    this._getPublicProjects();

    // TODO - handle storage data deletion
    // TODO - handle case where other users might be using project
    // TODO - refactor so manual calls of public/user projects not required (if can find efficient subscription)
  }

  /** reset all data in the current active project */
  public async wipeActiveProject() {
    const { project, subproject, deployment, language } = this.activeProject;
    const ref = `project/${project}/subproject/${subproject}/deployment/${deployment}/language/${language}/strings`;
    const projectStrings = await this.afs.collection(ref).get().toPromise();
    await this.deleteDBDocuments(
      projectStrings.docs.map((d) => ({ ...d.data(), id: d.id } as any))
    );
  }

  /**
   * Public method to allow manual call to refreshing user project data
   * (unlike active project, full list of all projects not updated in realtime,
   * so good to call from any project overview page)
   */
  public reloadUserProjects() {
    if (this.user && this.user.email) {
      this._getUserProjects(this.user);
    }
  }

  async lockDocument(docId: string) {
    // Ensure user has an id before locking
    if (!this.user) {
      await this.authService.signInAnonymously();
    }
    // release existing locked documents
    for (const doc of this.userLockedDocuments$.value) {
      this.unlockDocument(doc._docId);
    }
    const documentPath = `${this.dbPath}/${docId}`;
    // NOTE - the server checks for expired douments every 30 minutes, so
    // expiry specified here will be lower bound of time and time + 30 minutes
    const EXPIRY_MINUTES = 30;
    const expiry = new Date();
    expiry.setTime(new Date().getTime() + EXPIRY_MINUTES * 60 * 1000);
    await this.afs.doc<IDBDocument>(documentPath).update({
      _lockedBy: this.user.uid,
      _lockExpires: expiry,
      _locked: true,
    });
  }
  unlockDocument(docId: string) {
    const documentPath = `${this.dbPath}/${docId}`;
    this.afs.doc(documentPath).update({
      _lockedBy: firebase.firestore.FieldValue.delete(),
      _lockedAt: firebase.firestore.FieldValue.delete(),
      _locked: false,
    });
  }

  /**
   * Upload file to storage
   * Returns a task that must be subscribed to initiate
   * NOTE - functions storage trigger `writeUploadsToDB` handles automatically
   * updating corresponding DB entry on upload
   */
  createUploadTask(filename: string, storagePath: string, data: Blob) {
    const filePath = `${storagePath}/${filename}`;
    const { uid, email } = this.user;
    const meta: IStorageUploadMetadata = {
      uploadedByID: uid,
      uploadedByEmail: email || null,
    };
    const task = this.storage.upload(filePath, data, {
      customMetadata: meta as any,
    });
    return task;
  }

  getDBDocument(docPath: string) {
    return this.afs.doc(docPath).get().toPromise();
  }

  /**
   * NOTE - functions storage trigger `removeUploadsFromDB` handles automatically
   * updating corresponding DB entry on delete
   * @param path storage path in default bucket (e.g uploads/my-recording.mp4)
   */
  deleteStorageFile(path: string) {
    return this.storage.ref(path).delete();
  }

  async uploadDBDocuments(docs: IDBDocument[]) {
    // batch as firestore has write limit of 500 docs per operation
    const chunks = this._arrayToChunks<IDBDocument>(docs, 500);
    for (const chunk of chunks) {
      const batch = this.afs.firestore.batch();
      for (const doc of chunk) {
        const { id } = doc;
        const ref = this.afs.firestore.doc(`${this.dbPath}/${id}`);
        batch.set(ref, doc, { merge: true });
      }
      await batch.commit();
    }
  }
  async deleteDBDocuments(docs: IDBDocument[]) {
    // batch as firestore has write limit of 500 docs per operation
    const chunks = this._arrayToChunks<IDBDocument>(docs, 500);
    for (const chunk of chunks) {
      const batch = this.afs.firestore.batch();
      for (const doc of chunk) {
        const { id } = doc;
        const ref = this.afs.firestore.doc(`${this.dbPath}/${id}`);
        batch.delete(ref);
      }
      await batch.commit();
    }
  }
  /****************************************************************************
   * Private Methods
   ***************************************************************************/

  private _updateUserDocs(u: firebase.User) {
    this.userLockedDocuments$.next([]);
    this.userProjects$.next([]);
    this._removeSubscription('userLockedDocs');
    if (u) {
      if (!u.isAnonymous) {
        this._getUserProjects(u);
      }
      if (this.activeProject$.value) {
        this._subscribeToUserLockedDocUpdates(u);
      }
    }
  }
  /**
   * Keep track of whether a user is admin for a project. Useful for hiding/disabling
   * content for non-users
   */
  private _checkUserProjectPermissions() {
    const project = this.activeProject || { admins: [] };
    const user = this.user || { email: 'NA' };
    this.userIsProjectAdmin = project.admins.includes(user.email);
  }

  /**
   * Extract data and id from snapshot, and track the collection path the doc came from
   */
  private _processDocUpdate(docs: DocumentChangeAction<IDBDocument>[]) {
    return docs.map((d) => ({
      ...d.payload.doc.data(),
      _docId: d.payload.doc.id,
      _docPath: `${this.dbPath}/${d.payload.doc.id}`,
    }));
  }

  /****************************************************************************
   * Subscriptions
   ***************************************************************************/
  // TODO - could possibly update project in userProjects/publicProjects on update
  private _subscribeToActiveProject(project: IProject) {
    // project metadata stored in parent document of strings collection
    const projectDataRef = this.afs.collection(project.dbPath).ref.parent;
    const obs = this.afs
      .doc<IProject>(projectDataRef)
      .snapshotChanges()
      .pipe(
        tap((v) => console.log('active project', v.payload.data())),
        map((v) => ({
          ...v.payload.data(),
          dbPath: `${v.payload.ref.path}/strings`,
        }))
      );
    this._addSubscription('activeProject', obs, this.activeProject$);
  }

  private _subscribeToUserLockedDocUpdates<T>(user: firebase.User) {
    const obs = this.afs
      .collection<IDBDocument & T>(this.dbPath, (ref) =>
        ref.where('_lockedBy', '==', user.uid).limit(5)
      )
      .snapshotChanges()
      .pipe(map((docs) => this._processDocUpdate(docs)));
    this._addSubscription('userLockedDocs', obs, this.userLockedDocuments$);
  }

  /**
   * Make a single call to retrieve list of user projects
   * (updates aren't subscribed, and so require refresh or manual trigger elsewhere)
   */
  private _getUserProjects(user: firebase.User) {
    if (user.email) {
      this.userProjectsLoading = true;
      this.afs
        // 'language' is the last level of hierarchy when creating a project,
        // (i.e.) project -> subproject -> deployment -> language
        // so project metadata can be found there
        // TODO - include filter for user project membership
        // .collectionGroup('language', (ref) => ref.where('public', '==', false))
        .collectionGroup('language', (ref) =>
          ref.where('admins', 'array-contains', user.email.toLowerCase())
        )
        .get()
        .pipe(
          map<QuerySnapshot<IProject>, IProject[]>((snapshot) => {
            return snapshot.docs.map((d) => ({
              ...d.data(),
              dbPath: `${d.ref.path}/strings`,
            }));
          }),
          tap((v) => console.log('user projects', v))
        )
        // this doesn't need to be called every time but a custom tapOnce function would be more overhead
        .pipe(
          tap((v) => {
            logCount(v.length);
            this.userProjectsLoading = false;
          })
        )
        // do not need to add to main list of subscriptions as closes automatically after get
        .subscribe((v) => this.userProjects$.next(v));
    }
  }
  /**
   * Make a single call to retrieve list of public projects
   * (updates aren't subscribed, and so require refresh or manual trigger elsewhere)
   */
  private async _getPublicProjects() {
    const obs = this.afs
      .collectionGroup('language', (ref) => ref.where('public', '==', true))
      .get()
      .pipe(
        map<QuerySnapshot<IProject>, IProject[]>((snapshot) => {
          return snapshot.docs.map((d) => ({
            ...d.data(),
            dbPath: `${d.ref.path}/strings`,
          }));
        }),
        tap((v) => logCount(v.length))
      ) // do not need to add to main list of subscriptions as closes automatically after get
      .subscribe((v) => this.publicProjects$.next(v.sort((a, b) => (a.label > b.label ? 1 : -1))));
  }
  /** Retreive a list of all top-level project metadata, such as super-admins */
  private async _getProjectMeta<T>(project_id: string = environment.DEFAULT_PROJECT) {
    const obs = this.afs
      .doc<IProjectMeta>(`project/${project_id}`)
      .get()
      // no need to add to main subscriber list as self-closes after get
      .subscribe((v) => this.projectMeta$.next(v.data() as IProjectMeta));
  }

  /**
   * Stream live updating list of first 5 documents that are not locked and
   * do not already have media
   */
  private _subscribeToLockedDocUpdates<T>() {
    const obs = this.afs
      .collection<IDBDocument & T>(this.dbPath, (ref) => ref.where('_locked', '==', true))
      .snapshotChanges()
      .pipe(map((docs) => this._processDocUpdate(docs)));
    this._addSubscription('lockedDocs', obs, this.lockedDocs$);
  }

  /**
   * As there is lots of data updating in realtime, keep track of all open subscribers,
   * removing old ones when new are added
   */
  private _addSubscription(
    identifier: ISubscriptionIdentifier,
    observable: Observable<any>,
    subscriber: BehaviorSubject<any>
  ) {
    if (this.subscriptions[identifier]) {
      this.subscriptions[identifier].unsubscribe();
    }
    this.subscriptions[identifier] = observable
      .pipe(
        tap((v) => {
          if (Array.isArray(v)) {
            logCount(v.length);
          } else {
            // assumes not an object representing with docs
            logCount(1);
          }
        })
      )
      .subscribe(subscriber);
  }

  private _removeSubscription(identifier: ISubscriptionIdentifier) {
    if (this.subscriptions[identifier]) {
      this.subscriptions[identifier].unsubscribe();
    }
  }
  /**
   * Avoid memory leaks and multiple store listeners by binding all subscriptions to
   * an event emitter that can also destroy them
   */
  private _removeAllSubscriptions() {
    Object.values(this.subscriptions).forEach((s) => s.unsubscribe());
  }

  /****************************************************************************
   * Helpers
   ***************************************************************************/

  private _arrayToChunks<T>(arr: T[], chunk_size: number) {
    const chunks = [];
    while (arr.length) {
      chunks.push(arr.splice(0, chunk_size));
    }
    return chunks;
  }
}

/** Counter used to inspect number of db reads made */
function logCount(increment: number, label?: string) {
  count += increment;
  // console.log(label || 'db reads', count);
}

/****************************************************************************
 * Interfaces
 ***************************************************************************/
type ISubscriptionIdentifier = 'activeProject' | 'userLockedDocs' | 'lockedDocs';
